<template>
	<v-sheet>
		<audio
			id="player"
			controls
			src="https://firebasestorage.googleapis.com/v0/b/mwx2-753d3.appspot.com/o/audiocheck.net_sin_1000Hz_-3dBFS_3s.wav?alt=media&token=1c767157-51ec-48e8-892e-80f81d7a139b"
		>
			Your browser does not support the
			<code>audio</code> element.
		</audio>
	</v-sheet>
</template>

<script>
export default {
	name: "TestSpeakers",
	mounted() {
		const player = document.getElementById("player");
        const self = this;
		player.addEventListener("ended", () => {
            self.$emit("success", true )
		});
	},
};
</script>
