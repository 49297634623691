<template>
	<content-page>
		<template v-slot:content>
			<render-content id="techtest"></render-content>
			<v-sheet class="mb-10">
				<template>
					<v-stepper v-model="step">
						<v-stepper-header flat>
							<v-stepper-step :complete="step > 1" step="1">
								Test Speakers
							</v-stepper-step>
							<v-divider></v-divider>
							<v-stepper-step :complete="step > 2" step="2">
								Test Microphone
							</v-stepper-step>
							<v-divider></v-divider>
							<v-stepper-step step="3">
								Test Video
							</v-stepper-step>
						</v-stepper-header>

						<v-stepper-items flat>
							<v-stepper-content step="1">
                                <test-speakers @success="v => speakers = v"></test-speakers>

								<p class="text-right">
									<v-btn color="primary" @click="step = 2">
										Continue
									</v-btn>
								</p>
							</v-stepper-content>

							<v-stepper-content step="2">
								
                                <test-mic @success="v => mic = v"></test-mic>

								<p class="text-right">
									<v-btn color="primary" @click="step = 3">
										Continue
									</v-btn>
								</p>
							</v-stepper-content>

							<v-stepper-content step="3">
								
                                <test-camera @success="v => camera = v"></test-camera>

								<p class="text-right">
									<v-btn @click="done" color="primary">
										Done
									</v-btn>
								</p>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</template>
			</v-sheet>
		</template>
	</content-page>
</template>

<script>
import TestMic from "@c/tech-test/TestMic";
import RenderContent from "@c/ui/RenderContent";
import ContentPage from "@c/ui/ContentPage";
import TestCamera from "@c/tech-test/TestCamera.vue";
import TestSpeakers from "@c/tech-test/TestSpeakers.vue";
export default {
	name: "TechTest",
	components: {
		RenderContent,
		ContentPage,
		TestMic,
		TestCamera,
        TestSpeakers
	},
	data: () => {
		return {
			step: 1,
            camera: null, 
            speakers: null, 
            mic: null
		};
	},
    methods: {
        done(){
            const self = this;
            self.$store.dispatch("auth/patch", {
                "tech_tested": { 
                    updated_at: new Date(),
                    speakers: self.speakers, 
                    mic: self.mic, 
                    camera: self.camera, 
                }
            } ).then( ( ) => {
                self.$router.replace("/")
            })
        }
    }
};
</script>
